import React from 'react';
import { Banner } from 'components/banner/Banner';
import { RichText } from 'components/rich-text/RichText';

export const BannerSlice = ({ tagline, title, text, link, label, image, side }) => {
  return (
    <Banner
      tagline={tagline}
      title={title}
      text={RichText.render(text)}
      link={link}
      label={label}
      image={image}
      side={side}
    />
  );
};

export default BannerSlice;
