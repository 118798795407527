import React, { ReactNode, SyntheticEvent } from 'react';
import classnames from 'classnames';

import { ILink } from 'utils/types';
import ChevronRight from 'components/assets/chevron-right.svg';
import { IconButton } from 'components/button/IconButton';

import s from './MegaButton.module.scss';

type Props = {
  link?: ILink;
  color?: 'brand' | 'white' | 'dark';
  hover?: 'brand' | 'white' | 'dark';
  caption?: React.ReactNode;
  subline?: string;
  label?: React.ReactNode;
  ariaLabel?: string;
  number?: string;
  children?: React.ReactNode;
  onClick?: (event?: React.MouseEvent) => void;
};

export const MegaButton = ({
  link,
  color = 'brand',
  caption,
  subline,
  label,
  ariaLabel,
  number,
  children,
  ...rest
}: Props) => {
  const hasNumber = number && number.trim().length > 0;
  const hasChildren = React.Children.count(children) > 0;
  const hasCaption =
    caption &&
    (typeof caption === 'string' ||
      (typeof caption !== 'string' && React.Children.count(caption) > 0));

  const className = classnames(s.megaButton, {
    [s.brand]: color === 'brand',
    [s.white]: color === 'white',
    [s.dark]: color === 'dark',
    [s.hasNumber]: hasNumber,
    [s.noContent]: !hasChildren,
  });

  const content = (
    <div className={s.megaButton__content}>
      {number && <div className={s.megaButton__number}>{number}</div>}
      <div className={s.megaButton__text}>
        {children}
        {hasCaption && (
          <div className={s.megaButton__icon}>
            <ChevronRight />
          </div>
        )}
      </div>
    </div>
  );

  const captionContent = (
    <div className={s.megaButton__caption}>
      {hasCaption && <div className={s.megaButton__name}>{caption}</div>}
      {hasCaption && subline && <div className={s.megaButton__title}>{subline}</div>}
    </div>
  );

  return (
    <div className={className}>
      {hasChildren && content}
      {hasCaption && captionContent}
      <div className={s.megaButton__button}>
        <IconButton
          {...link}
          color={color == 'white' ? 'dark' : 'mega'}
          aria-label={label || ariaLabel || 'Read more'}
          {...rest}
        >
          {label}
        </IconButton>
      </div>
    </div>
  );
};
